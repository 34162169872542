<template>
    <v-flex lg12 mb-0 pb-0 mt-0 pt-0>
        <div style="height: 12px" v-if="alertUndismissed"></div>
        <v-alert
                :close-text="alert"
                :color="type"
                :value="showAlert"
                border="left"
                dense
                icon="warning"
                style="height: unset; margin-bottom: 0px;"
                text
                width="auto"
                @input="alertUndismissed = false"
        >{{ $t('message.updatedShipmentUnsaved') }}</v-alert>
    </v-flex>
</template>

<script>
    export default {
        name: "ShipmentAlert",
        data() {
            return {
                alertUndismissed: false
            }
        },
        props: ['alert', 'type', 'show'],
        computed: {
            showAlert: {
                get()
                {
                    return this.show
                },
                set(value) {
                    this.showAlert = value
                }
            }
        }
    }
</script>

<style scoped>

</style>